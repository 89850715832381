.talk {
  h1, h2, h3, h4, h5, h6 {
   @include sans-serif-headers;
  }

  $background-highlight: #efefef;

  a {
    color: #0000EE;
    text-decoration: underline;
    &:visited {
      color: #551A8B;
    }
  }

  img {
    // max-height: 200px;
    width: 100%;
  }

  .slide-number {
    position: absolute;
    color: white;
    right: 30px;
    top: 5px;
    opacity: 0.3;
    font-size: 1.5rem;
  }

  .slide-description {
    border-radius: .25rem;
    background-color: white;
    padding: 1rem;

    header {
      font-family: $sans-serif;
      font-size: 0.8rem;
      background-color: $background-highlight;
      padding: 5px;
      margin-bottom: 1rem;
    }

    pre {
      background-color: $background-highlight;
      padding: 1rem;
    }
  }
}