/*
 * Makes the image break out of the centered text column to spread to each side.
 */
.full-width-image {
    width: 100vw;
    position: relative;
    left: 50%;
    margin-left: -50vw;
}

.full-width-image img {
    width: 100%;
}

.avatar {
    overflow: hidden;
    border-radius: 50%;
    border: 1px solid $dark;
}