/* stylelint-disable selector-list-comma-newline-after */

// Colors

$light: #dad8da;
$medium: #cccccc;
$muted: #5c412e;
$dark: #3a2517;
$comp: #8b2a07;

html {
  position: relative;
  min-height: 100%;
}

body {
  background-color: $light;
  color: $dark;
}

main {
  margin-bottom: 150px;
  /* Margin bottom by footer height */
}

.text-muted {
  color: $muted !important;
}

.recent-posts-header {
  border-bottom: 1px solid $dark;
}

.content {
  color: $dark;

  a {
    text-decoration: underline;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    padding: 1rem 0;
  }
}

.art-credits {
  a {
    text-decoration: underline;
  }
}

@import "shim";
@import "components/tags";
@import "components/typography";
@import "components/images";
@import "components/post";
@import "components/project";
@import "components/pagination";
@import "components/header";
@import "components/footer";
@import "components/jumbotron";
@import "components/links";
@import "components/cv";
@import "components/talks";
@import "components/twitter";
@import "pages/home";
