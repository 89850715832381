
$serif: "Crimson Text", serif;
$sans-serif: "Lato", sans-serif;

h1, h2, h3, h4, h5, h6 {
    font-family: $sans-serif;
    font-weight: 600;
    font-style: normal;
}

@mixin sans-serif-headers {
    font-family: $sans-serif;
    font-style: normal;
    font-weight: 500;
}

.content {
  font-size: 16pt;
  font-family: $serif;
}
