/* stylelint-disable selector-list-comma-newline-after */
html {
  position: relative;
  min-height: 100%;
}
body {
  background-color: #dad8da;
  color: #3a2517;
}
main {
  margin-bottom: 150px;
  /* Margin bottom by footer height */
}
.text-muted {
  color: #5c412e !important;
}
.recent-posts-header {
  border-bottom: 1px solid #3a2517;
}
.content {
  color: #3a2517;
}
.content a {
  text-decoration: underline;
}
.content h1,
.content h2,
.content h3,
.content h4,
.content h5,
.content h6 {
  padding: 1rem 0;
}
.art-credits a {
  text-decoration: underline;
}
.card-columns {
  orphans: 1;
  widows: 1;
}
a.set-1 {
  font-size: 12pt;
}
a.set-2 {
  font-size: 13pt;
}
a.set-3 {
  font-size: 14pt;
}
a.set-4 {
  font-size: 15pt;
}
a.set-5 {
  font-size: 16pt;
}
a.set-6 {
  font-size: 18pt;
}
h1, h2, h3, h4, h5, h6 {
  font-family: "Lato", sans-serif;
  font-weight: 600;
  font-style: normal;
}
.content {
  font-size: 16pt;
  font-family: "Crimson Text", serif;
}
/*
 * Makes the image break out of the centered text column to spread to each side.
 */
.full-width-image {
  width: 100vw;
  position: relative;
  left: 50%;
  margin-left: -50vw;
}
.full-width-image img {
  width: 100%;
}
.avatar {
  overflow: hidden;
  border-radius: 50%;
  border: 1px solid #3a2517;
}
.blog-post-summary header {
  border-bottom: 1px solid #3a2517;
}
.blog-post-summary:last-child {
  border-bottom: none;
}
.post-image-card {
  box-shadow: 2px 2px 3px #737373;
  border: 1px solid #3a2517;
}
.blog-post-title {
  margin-bottom: 1rem;
  padding-top: 1.4rem;
  font-size: 2.5rem;
  color: #3a2517;
}
.blog-post-title a {
  color: #3a2517;
}
.post-separator:last-child {
  display: none;
}
.post-separator hr {
  overflow: visible;
  /* For IE */
  margin: 1em 0;
  border: none;
  border-top: medium double #333;
  color: #333;
  text-align: center;
}
.post-separator hr:after {
  content: "";
  display: inline-block;
  position: relative;
  height: 50px;
  width: 50px;
  top: -31px;
  padding: 0 0.75em;
  background-image: url("https://static.whazz.io/site/whazz-design-logo-circle-muted.svg");
  background-size: 50px 50px;
  background-color: #dad8da;
  background-repeat: no-repeat;
  background-position: center;
}
.blog-post header {
  border-bottom: 1px solid #3a2517;
}
.blog-post h1,
.blog-post h2,
.blog-post h3,
.blog-post h4,
.blog-post h5,
.blog-post h6 {
  font-family: "Lato", sans-serif;
  font-style: normal;
  font-weight: 500;
}
.blog-post-meta {
  margin-bottom: 1.25rem;
  color: #5c412e;
}
.blog-post-content {
  padding-top: 1em;
  font-size: 16pt;
  font-family: "Crimson Text", serif;
  color: #3a2517;
}
.project-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.project-list-card {
  max-width: 30%;
  margin: 1em;
}
.project-list-card .project-date {
  font-size: 10pt;
}
.project-list-card .project-description {
  height: 50px;
}
.project h1, .project h2, .project h3, .project h4, .project h5, .project h6 {
  font-family: "Lato", sans-serif;
  font-style: normal;
  font-weight: 500;
}
.page-item.active .page-link {
  background-color: #8b2a07;
  border: 1px solid #8b2a07;
}
.page-link {
  color: #8b2a07;
}
.page-link:hover {
  color: #8b2a07;
}
#search input[type=text] {
  border: 1px solid #ddd;
  padding: 6px;
}
.blog-header {
  line-height: 1;
  border-bottom: 1px solid #3a2517;
}
.blog-header-logo {
  padding-top: 1rem;
}
.blog-header-logo a {
  color: #3a2517;
  font-family: "Crimson Text", serif;
  font-weight: 600;
  font-style: normal;
  font-size: 2.25rem;
}
.blog-header-logo a:hover {
  text-decoration: none;
  color: #8b2a07;
}
.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  /* Set the fixed height of the footer here */
  /* Vertically center the text there */
  background-color: #3a2517;
  color: #dad8da;
}
.footer a {
  color: #dad8da;
  text-decoration: underline;
}
.footer a:hover {
  color: #dad8da;
}
.footer ul {
  display: inline-block;
  list-style: none;
  padding-left: 0;
}
.footer .social-links {
  font-size: 2rem;
}
.footer .social-links a {
  vertical-align: text-bottom;
}
.footer .social-links a.logo {
  display: inline-block;
  padding-bottom: 4px;
}
.jumbotron {
  background-size: cover;
}
a {
  color: #3a2517;
}
a:hover {
  color: #8b2a07;
}
.cv {
  background-color: #ffffff;
  border: 1px solid #3a2517;
  padding: 20px;
}
.cv .avatar {
  max-height: 100%;
  max-width: 100px;
}
.cv a {
  color: #0000EE;
  text-decoration: underline;
}
.cv a:visited {
  color: #551A8B;
}
.cv h1,
.cv h2,
.cv h3,
.cv h4,
.cv h5,
.cv h6 {
  font-family: "Lato", sans-serif;
  font-style: normal;
  font-weight: 500;
  padding: 0;
  margin: 0;
}
.cv header {
  padding: 1rem 0;
}
.cv header .subtitle {
  font-family: "Crimson Text", serif;
  font-size: 1.1rem;
}
.cv header a {
  text-decoration: underline;
}
.cv h2.section-title {
  color: #aaa;
  font-weight: 500;
  padding: 1rem 0;
}
.cv ul {
  list-style: none;
  padding-left: 0;
}
.cv ul.skills-list li {
  font-style: italic;
}
.cv ul.thoughts-list li {
  padding-bottom: 0.4rem;
}
.cv .position {
  padding-bottom: 0.5rem;
}
.cv .position h3 {
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
}
.cv .position h3 .title {
  color: #888;
  font-size: 80%;
}
.cv .position .description {
  padding: 0.5rem 0;
  font-family: "Crimson Text", serif;
  font-size: 1.1rem;
  line-height: 125%;
}
@media print {
  .cv {
    border: none;
  }
  .cv a {
    text-decoration: none !important;
    color: #3a2517;
  }
  .cv a:visited {
    color: #3a2517;
  }
  .cv i {
    font-family: "Crimson Text", serif;
  }
  .cv ul.skills-list {
    font-family: "Crimson Text", serif;
  }
  .cv header h1 {
    text-align: left;
  }
  .cv header .subtitle {
    text-align: left;
    white-space: nowrap;
  }
  .cv .position .title::before {
    content: " ";
    white-space: pre;
  }
  .cv .sidebar {
    page-break-before: always;
  }
  .cv .row {
    display: block;
  }
}
.talk h1, .talk h2, .talk h3, .talk h4, .talk h5, .talk h6 {
  font-family: "Lato", sans-serif;
  font-style: normal;
  font-weight: 500;
}
.talk a {
  color: #0000EE;
  text-decoration: underline;
}
.talk a:visited {
  color: #551A8B;
}
.talk img {
  width: 100%;
}
.talk .slide-number {
  position: absolute;
  color: white;
  right: 30px;
  top: 5px;
  opacity: 0.3;
  font-size: 1.5rem;
}
.talk .slide-description {
  border-radius: 0.25rem;
  background-color: white;
  padding: 1rem;
}
.talk .slide-description header {
  font-family: "Lato", sans-serif;
  font-size: 0.8rem;
  background-color: #efefef;
  padding: 5px;
  margin-bottom: 1rem;
}
.talk .slide-description pre {
  background-color: #efefef;
  padding: 1rem;
}
blockquote.twitter-tweet {
  display: inline-block;
  font-family: "Helvetica Neue", Roboto, "Segoe UI", Calibri, sans-serif;
  font-size: 12px;
  font-weight: bold;
  line-height: 16px;
  border-color: #eee #ddd #bbb;
  border-radius: 5px;
  border-style: solid;
  border-width: 1px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);
  margin: 10px 5px;
  padding: 0 16px 16px 16px;
  max-width: 468px;
}
blockquote.twitter-tweet p {
  font-size: 16px;
  font-weight: normal;
  line-height: 20px;
}
blockquote.twitter-tweet a {
  color: inherit;
  font-weight: normal;
  text-decoration: none;
  outline: 0 none;
}
blockquote.twitter-tweet a:hover,
blockquote.twitter-tweet a:focus {
  text-decoration: underline;
}
.tweet-container {
  display: flex;
  justify-content: center;
  padding-bottom: 18px;
}
.posts-home .blog-post-title {
  border-bottom: 1px dashed #5c412e;
  padding: 1rem 1rem 0.5rem 0;
  color: #5c412e;
}
.posts-home .blog-post-title a {
  color: #5c412e;
}
/*# sourceMappingURL=main.css.map */
