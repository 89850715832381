#search input[type=text] {
    border: 1px solid #ddd;
    padding: 6px;
}

.blog-header {
    line-height: 1;
    border-bottom: 1px solid $dark;
}

.blog-header-logo {
    padding-top: 1rem;

    a {
        color: $dark;
        font-family: $serif;
        font-weight: 600;
        font-style: normal;
        font-size: 2.25rem;
    }

    a:hover {
        text-decoration: none;
        color: $comp;
    }
}
