.posts-home {
  .blog-post-title {
    // background-color: lighten($muted, 5%);
    border-bottom: 1px dashed $muted;
    padding: 1rem 1rem 0.5rem 0;
    color: $muted;
    a {
      color: $muted;
    }
  }

}