.footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    //height: 100px;
    /* Set the fixed height of the footer here */
    // line-height: 100px;
    /* Vertically center the text there */
    background-color: $dark;
    color: $light;

    a {
        color: $light;
        text-decoration: underline;
        &:hover { color: $light; }
    }

    ul {
        display: inline-block;
        list-style: none;
        padding-left: 0;

    }

    .social-links {
        font-size: 2rem;
        a {
            vertical-align: text-bottom;
            &.logo { display: inline-block; padding-bottom: 4px; }
        }
    }
}
