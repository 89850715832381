.blog-post-summary {
  header {
    border-bottom: 1px solid $dark;
  }

  &:last-child {
    border-bottom: none;
  }
}

.post-image-card {
  box-shadow: 2px 2px 3px lighten(#666, 5%);
  border: 1px solid $dark;
}

.blog-post-title {
  margin-bottom: 1rem;
  padding-top: 1.4rem;
  font-size: 2.5rem;
  color: $dark;
  a {
    color: $dark;
  }
}

.post-separator {
  &:last-child {
    display: none;
  }

  hr {
    overflow: visible; /* For IE */
    margin: 1em 0;
    border: none;
    border-top: medium double #333;
    color: #333;
    text-align: center;
  }
  hr:after {
    content: '';

    display: inline-block;
    position: relative;
    height: 50px;
    width: 50px;
    top: -31px;
    padding: 0 .75em;

    background-image: url("https://static.whazz.io/site/whazz-design-logo-circle-muted.svg");
    background-size: 50px 50px;
    background-color: $light;
    background-repeat: no-repeat;
    background-position: center;
  }
}

.blog-post {
  header {
    border-bottom: 1px solid $dark;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @include sans-serif-headers;
  }
}

.blog-post-meta {
  margin-bottom: 1.25rem;
  color: $muted;
}

.blog-post-content {
  padding-top: 1em;
  font-size: 16pt;
  font-family: $serif;
  color: $dark;
}
