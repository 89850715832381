$old-paper: #f8ecc2;
$new-paper: #ffffff;

.cv {
  background-color: $new-paper;
  border: 1px solid $dark;
  padding: 20px;

  .avatar {
    max-height: 100%;
    max-width: 100px;
  }
  a {
    color: #0000EE;
    text-decoration: underline;
    &:visited {
      color: #551A8B;
    }
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @include sans-serif-headers;
    padding: 0;
    margin: 0;
  }
  header {
    padding: 1rem 0;
    .subtitle {
      font-family: $serif;
      font-size: 1.1rem;
    }
    a {
      text-decoration: underline;
    }
  }
  h2.section-title {
    color: #aaa;
    font-weight: 500;
    padding: 1rem 0;
  }
  ul {
    list-style: none;
    padding-left: 0;
    &.skills-list {
      li {
        font-style: italic;
      }
    }
    &.thoughts-list {
      li {
        padding-bottom: 0.4rem;
      }
    }
  }
  .position {
    padding-bottom: .5rem;
    h3 {
      display: flex;
      flex-wrap: wrap;
      align-items: baseline;
      .title {
        color: #888;
        font-size: 80%;
      }
    }
    .description {
      padding: 0.5rem 0;
      font-family: $serif;
      font-size: 1.1rem;
      line-height: 125%;
    }
  }
}

@media print {
  .cv {
    border: none;

    a {
      text-decoration: none !important;
      color: #3a2517;
      &:visited { color: #3a2517; }
    }

    i { font-family: $serif; }
    ul.skills-list { font-family: $serif; }

    header {
      h1 { text-align: left; }
      .subtitle {
        text-align: left;
        white-space: nowrap;
      }
    }

    .position {
      .title::before {
        content: ' ';
        white-space: pre;
      }
    }

    .sidebar {
      page-break-before: always;
    }

    .row {
      display: block;
    }
  }
}
