.project-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.project-list-card {
  max-width: 30%;
  margin: 1em;

  .project-date { font-size: 10pt; }
  .project-description {
    height: 50px;
  }
}

.project {
  h1, h2, h3, h4, h5, h6 {
   @include sans-serif-headers;
  }
}